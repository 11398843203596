/* eslint-disable no-param-reassign */
import { create } from 'zustand';
import { produce } from 'immer';
import SIGNUP_SCREEN_DISMISSED_SCHEMA from 'src/lib/delta/delta-schemas/ppWebCreatorsSignupScreenDismissed';
import SIGNUP_SCREEN_PRESENTED_SCHEMA from 'src/lib/delta/delta-schemas/ppWebCreatorsSignupScreenPresented';
import { flowNames } from 'src/lib/delta/deltaConstants';
import DeltaHelper from 'src/services/delta/DeltaHelper';
import Delta from 'src/utils/wrappers/Delta';
import { Platform, SOCIAL_PLATFORMS, SocialPlatform } from '../platforms';
import { STEP_UUID_MAP } from '../stepUuidMap';
import { Step, STEPS } from '../wizardSteps';

type UserIdentity = {
  provider: string;
  cachedProfileImageUrl?: string;
};

export type User = {
  id: string;
  platforms: Platform[];
  birthDate: Date;
  email: string;
  identities?: UserIdentity[];
};

type ConnectPlatformsState = {
  step: Step;
  previousStep: Step;
  user?: User;
  avatarUrl?: string;
  selectedPlatforms: SocialPlatform[];
  connectedPlatforms: Platform[];
  // There are some platforms which are connected by the same auth. For example,
  // Instagram and Facebook are connected by the same auth (Facebook auth).
  distinctConnectedPlatforms: Platform[];
  errorMessage?: string;
  brandName?: string;
};

type ConnectPlatformsActions = {
  setStep: (step: Step) => void;
  setUser: (user: User) => void;
  setSelectedPlatforms: (platforms: SocialPlatform[]) => void;
  setConnectedPlatforms: (platforms: Platform[]) => void;
  setError: (error?: string) => void;
  setAvatarUrl: (avatarUrl?: string) => void;
  setDistinctConnectedPlatforms: (platforms: Platform[]) => void;
  setBrandName: (brandName?: string) => void;
};

const sendSignUpScreenEvent = (step: Step, eventType: 'presented' | 'dismissed') => {
  const flow = DeltaHelper.getOrCreateFlow(flowNames.brandsSafetyConnectPlatforms);
  const presentedDetails: any = {};

  if (step === STEPS.SELECT_PLATFORMS) {
    presentedDetails.selectedPlatforms = useConnectPlatformsStore.getState().selectedPlatforms;
    presentedDetails.connectedPlatforms = useConnectPlatformsStore.getState().connectedPlatforms;
    presentedDetails.SOCIAL_PLATFORMS = SOCIAL_PLATFORMS;
  }

  const schema =
    eventType === 'presented'
      ? SIGNUP_SCREEN_PRESENTED_SCHEMA.name
      : SIGNUP_SCREEN_DISMISSED_SCHEMA.name;

  Delta.sendEvent(schema, {
    flow_id: flow.flow_id,
    screen_presentation_id: STEP_UUID_MAP[step],
    signup_screen_name: step,
    presented_details: presentedDetails
  });
};

export const sendSignUpScreenPresentedEvent = (step: Step) =>
  sendSignUpScreenEvent(step, 'presented');

export const sendSignUpScreenDismissedEvent = (step: Step) =>
  sendSignUpScreenEvent(step, 'dismissed');

export const useConnectPlatformsStore = create<ConnectPlatformsState & ConnectPlatformsActions>(
  (set) => ({
    step: STEPS.SELECT_PLATFORMS,
    previousStep: STEPS.SELECT_PLATFORMS,
    selectedPlatforms: [],
    connectedPlatforms: [],
    distinctConnectedPlatforms: [],
    setStep: (newStep: Step) =>
      set((state) => {
        if (state.step === newStep) {
          return state;
        }

        sendSignUpScreenDismissedEvent(state.step);
        sendSignUpScreenPresentedEvent(newStep);

        return produce(state, (draft) => {
          draft.previousStep = state.step;
          draft.step = newStep;
        });
      }),
    setUser: (user) =>
      set(
        produce((state) => {
          state.user = user;
        })
      ),
    setSelectedPlatforms: (platforms) =>
      set(
        produce((state) => {
          state.selectedPlatforms = platforms;
        })
      ),
    setConnectedPlatforms: (platforms) =>
      set(
        produce((state) => {
          state.connectedPlatforms = platforms;
        })
      ),
    setDistinctConnectedPlatforms: (platforms) =>
      set(
        produce((state) => {
          state.distinctConnectedPlatforms = platforms;
        })
      ),
    setError: (error) =>
      set(
        produce((state) => {
          state.errorMessage = error;
        })
      ),
    setAvatarUrl: (avatarUrl) =>
      set(
        produce((state) => {
          state.avatarUrl = avatarUrl;
        })
      ),
    setBrandName: (brandName) =>
      set(
        produce((state) => {
          state.brandName = brandName;
        })
      )
  })
);
