import React from 'react';
import clsx from 'clsx';
import { Icon, Headline, Button } from '@lightricks/react-design-system';

import translate from 'src/utils/translate';
import styles from './PlatformList.module.scss';
import { isVettablePlatform, Platform, VETTABLE_PLATFORMS } from '../platforms';

export type PlatformListProps = {
  selectedPlatforms: Platform[];
  connectedPlatforms: Platform[];
  onConnectPlatform: (platform: Platform) => void;
};

function PlatformList(props: PlatformListProps) {
  const { selectedPlatforms, connectedPlatforms, onConnectPlatform } = props;

  const renderActionButton = (platform: Platform, isConnected: boolean) => {
    if (!isVettablePlatform(platform)) {
      return null;
    }
    if (isConnected) {
      return <Icon name="Actions-Patch-Accept-Line" size="large" appearance="brand" />;
    }

    return (
      <Button
        appearance="neutral"
        size="small"
        mode="plain"
        onClick={() => {
          onConnectPlatform(platform);
        }}
        className={styles.connectButton}
        icon={<Icon name="Actions-Add-Small1" size="large" appearance="white" />}
      />
    );
  };

  const sortedPlatforms = [...selectedPlatforms].sort((a, b) => {
    return isVettablePlatform(a) ? -1 : 1;
  });

  return (
    <div className={styles.platformList}>
      <div className={styles.platformRow}>
        <div className={clsx(styles.platformItem, styles.completed)}>
          <Icon name="Navigation-Mail" size="medium" appearance="primary" />
          <Headline size="xs">
            {translate('components.brand-safety.sign-up.platform-list.email')}
          </Headline>
        </div>
        <Icon name="Actions-Patch-Accept-Line" size="large" appearance="brand" />
      </div>

      {sortedPlatforms.map((platform) => {
        const isConnected = connectedPlatforms.includes(platform);
        const label = VETTABLE_PLATFORMS.includes(platform)
          ? platform
          : translate('components.brand-safety.sign-up.platform-list.coming-soon');

        return (
          <div className={styles.platformRow} key={platform}>
            <div className={styles.platformItem}>
              <Icon name={`Social-${platform}` as any} size="medium" appearance="primary" />
              <Headline size="xs">{label}</Headline>
            </div>
            {renderActionButton(platform, isConnected)}
          </div>
        );
      })}
    </div>
  );
}

export default PlatformList;
