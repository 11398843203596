import React, { useEffect, useState } from 'react';
import { designSystemToken, Button, Headline, Body, Icon } from '@lightricks/react-design-system';
import { v4 as uuid } from 'uuid';
import translate from '../../utils/translate';
import styles from './OtpVerificationModal.module.scss';
import { OtpVerificationModalProps } from './OtpVerificationModalProps';
import Fortress, { VerifyOTPAndSaveTokenCookiesResponse } from '../../services/fortress/Fortress';
import isMobile from '../../utils/identifyDevice';
import { CircleCheckIcon, CloseModalIcon } from '../icons';
import OtpInput from '../otp-input/OtpInput';
import ModalLogin from '../modal-login';
import DeltaHelper from '../../services/delta/DeltaHelper';
import {
  buttonNames,
  deltaClientEvents,
  flowNames,
  reasonNames,
  screenNames
} from '../../lib/delta/deltaConstants';
import Delta from '../../utils/wrappers/Delta';

function OtpVerificationModal(props: OtpVerificationModalProps) {
  const {
    isOpen,
    email,
    authFlowType,
    authenticateCallback,
    setIsModalOpen,
    handleBackToEmail,
    redirectToSocialNetworks
  } = props;
  const [error, setError] = useState('');
  const [code, setCode] = useState('');
  const [isResendCodeLoading, setIsResendCodeLoading] = useState(false);
  const [isResendCodeSuccess, setIsResendCodeSuccess] = useState(false);
  const [isLoadingCodeVerify, setIsLoadingCodeVerify] = useState(false);
  const isMobileDevice = isMobile();
  const [otpInputs, setOtpInputs] = useState<HTMLInputElement[]>([]);

  const isLoginScreen = authFlowType === flowNames.auth.login;
  const flowEvent =
    DeltaHelper.getOrCreateFlow(isLoginScreen ? flowNames.auth.login : flowNames.auth.signup) || {};
  const screenNameEvent = isLoginScreen ? screenNames.auth.login_otp : screenNames.auth.signup_otp;
  const stepStartedEventName = isLoginScreen
    ? deltaClientEvents.auth.login_step_started
    : deltaClientEvents.auth.signup_step_started;
  const stepEndedEventName = isLoginScreen
    ? deltaClientEvents.auth.login_step_ended
    : deltaClientEvents.auth.signup_step_ended;
  const requestProviderFlow = isLoginScreen ? 'login' : 'signup';

  useEffect(() => {
    return () => {
      resetOtpModal();
    };
  }, []);

  useEffect(() => {
    if (!isOpen) {
      resetOtpModal();
    }
  }, [isOpen]);

  const resetOtpModal = () => {
    setIsResendCodeLoading(false);
    setIsResendCodeSuccess(false);
    setIsLoadingCodeVerify(false);
    setError('');
    setCode('');
  };

  const resendCode = async () => {
    Delta.sendEvent(deltaClientEvents.generic.button_pressed, {
      button_name: buttonNames.auth.resend_code,
      screen_name: screenNameEvent,
      flow_id: flowEvent.flow_id,
      flow_name: flowEvent.flow_name,
      triggered_flow_id: '',
      triggered_flow_name: '',
      screen_presentation_id: DeltaHelper.getActiveScreenPresented().screen_presentation_id
    });
    const processId = uuid();
    const stepDateEvent = {
      flow_id: flowEvent.flow_id,
      process_id: processId,
      [`${requestProviderFlow}_provider`]: 'fortress',
      request_type: 'send_otp',
      input_value: code,
      number_of_required_fields: isLoginScreen ? undefined : 0,
      number_of_fields_completed: isLoginScreen ? undefined : 0
    };
    Delta.sendEvent(stepStartedEventName, stepDateEvent);

    setIsResendCodeLoading(true);
    setIsResendCodeSuccess(false);
    setError('');
    resetOtpInputs();
    try {
      await Fortress.generateOTP(email);
      setIsResendCodeSuccess(true);

      Delta.sendEvent(stepEndedEventName, {
        ...stepDateEvent,
        reason: reasonNames.success
      });
    } catch (e: any) {
      setError(e?.message);

      Delta.sendEvent(stepEndedEventName, {
        ...stepDateEvent,
        reason: reasonNames.failure,
        error: e?.message || ''
      });
    }
    setIsResendCodeLoading(false);
  };

  const onVerifyButtonClick = async (otpCode: string): Promise<void> => {
    if (otpCode.length !== 6) {
      return;
    }
    Delta.sendEvent(deltaClientEvents.generic.button_pressed, {
      button_name: buttonNames.auth.continue,
      screen_name: screenNameEvent,
      flow_id: flowEvent.flow_id,
      flow_name: flowEvent.flow_name,
      triggered_flow_id: '',
      triggered_flow_name: '',
      screen_presentation_id: DeltaHelper.getActiveScreenPresented().screen_presentation_id
    });
    const processId = uuid();
    const stepDateEvent = {
      flow_id: flowEvent.flow_id,
      process_id: processId,
      [`${requestProviderFlow}_provider`]: 'fortress',
      request_type: 'verify_otp',
      input_value: code,
      number_of_required_fields: isLoginScreen ? undefined : 1,
      number_of_fields_completed: isLoginScreen ? undefined : 1
    };
    Delta.sendEvent(stepStartedEventName, stepDateEvent);

    const verifySession = await onVerifyCode(otpCode, stepDateEvent);
    if (verifySession?.token) {
      await authenticateCallback(verifySession.token, stepDateEvent);
    }
    if (redirectToSocialNetworks) {
      window.location.href = '/account/social-networks';
    }
  };

  const onVerifyCode = async (
    otpCode: string,
    stepDateEvent: Record<string, unknown>
  ): Promise<VerifyOTPAndSaveTokenCookiesResponse | null> => {
    let tokens = null;
    try {
      setError('');
      setIsLoadingCodeVerify(true);
      tokens = await Fortress.verifyOTPAndSaveTokenCookies(email, otpCode);
    } catch (e: any) {
      setError(e?.message);
      setIsLoadingCodeVerify(false);

      Delta.sendEvent(stepEndedEventName, {
        ...stepDateEvent,
        reason: reasonNames.failure,
        error: e?.message || ''
      });
    }
    return tokens;
  };

  const resetOtpInputs = () => {
    otpInputs.forEach((element: HTMLInputElement) => {
      element.value = ''; // eslint-disable-line no-param-reassign
    });
    setCode('');
    otpInputs?.[0].focus();
  };

  const onModalClose = () => {
    Delta.sendEvent(deltaClientEvents.generic.button_pressed, {
      button_name: buttonNames.auth.exit,
      screen_name: screenNameEvent,
      flow_id: flowEvent.flow_id,
      flow_name: flowEvent.flow_name,
      triggered_flow_id: '',
      triggered_flow_name: '',
      screen_presentation_id: DeltaHelper.getActiveScreenPresented().screen_presentation_id
    });

    const shouldHandleBackToEmail = authFlowType === 'brand_safety_signup' && handleBackToEmail;

    if (shouldHandleBackToEmail) {
      handleBackToEmail();
    } else {
      setIsModalOpen(false);
    }

    const currentScreen = isLoginScreen ? screenNames.auth.login_otp : screenNames.auth.signup_otp;
    const distentionScreen = isLoginScreen ? screenNames.auth.login : screenNames.auth.signup;

    DeltaHelper.screenDismissed(currentScreen, distentionScreen);
    DeltaHelper.screenPresented(distentionScreen, currentScreen);
  };

  const modalTitle =
    authFlowType !== 'brand_safety_signup'
      ? translate('templates.otp-verification-modal.title')
      : translate('templates.otp-verification-modal.brand-safety-title');

  const renderTitle = (
    <div className={styles.otpHeader}>
      <Button
        testID="close-modal-button"
        appearance="neutral"
        mode="plain"
        size="large"
        className={styles.otpCloseIcon}
        onClick={onModalClose}>
        <CloseModalIcon />
      </Button>
      <Headline size={isMobileDevice ? 'md' : 'xl'}>{modalTitle}</Headline>
    </div>
  );

  return (
    <ModalLogin testID="otp-modal" open={isOpen} title={renderTitle} onClose={onModalClose}>
      <div className={styles.otpContent}>
        <Body
          size={isMobileDevice ? 'lg' : 'xl'}
          color={designSystemToken('semantic.fg.secondary')}>
          {authFlowType !== 'brand_safety_signup'
            ? translate('templates.otp-verification-modal.email-label')
            : translate('templates.otp-verification-modal.brand-safety-email-label')}
        </Body>
        <Body
          size={isMobileDevice ? 'lg' : 'xl'}
          color={designSystemToken('semantic.fg.secondary')}>
          {email}
        </Body>
        <div className={styles.otpInput}>
          <OtpInput
            onTypingCode={setCode}
            setOtpInputElements={setOtpInputs}
            onSubmit={onVerifyButtonClick}
          />
          <Body
            size="sm"
            className={styles.otpError}
            color={designSystemToken('semantic.fg.danger')}
            dangerouslyInnerHTML={error}
          />
        </div>
        <div className={styles.otpContinueButton}>
          <Button
            type="submit"
            appearance={authFlowType === 'brand_safety_signup' ? 'neutral' : 'brand'}
            mode="filled"
            size="large"
            disabled={code.length < 6}
            onClick={() => onVerifyButtonClick(code)}
            isLoading={isLoadingCodeVerify}
            fullWidth>
            {translate('templates.otp-verification-modal.continue')}
          </Button>
        </div>
        <Button
          appearance="neutral"
          mode="plain"
          size="large"
          onClick={resendCode}
          isLoading={isResendCodeLoading}
          disabled={isResendCodeLoading}>
          {translate('templates.otp-verification-modal.resend-code')}
        </Button>
        <div className={styles.otpCodeResendSuccess}>
          {isResendCodeSuccess ? (
            <>
              <CircleCheckIcon />
              <Body size="sm">
                {translate('templates.otp-verification-modal.resend-code-success')}
              </Body>
            </>
          ) : null}
        </div>
      </div>
    </ModalLogin>
  );
}

export default OtpVerificationModal;
