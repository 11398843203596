import { flowNames } from 'src/lib/delta/deltaConstants';
import DeltaHelper from 'src/services/delta/DeltaHelper';
import Delta from 'src/utils/wrappers/Delta';
import SIGNUP_BUTTON_PRESSED_SCHEMA from 'src/lib/delta/delta-schemas/ppWebCreatorsSignupButtonPressed';
import SIGNUP_STEP_STARTED_SCHEMA from 'src/lib/delta/delta-schemas/ppWebCreatorsSignupStepStarted';
import { v4 as uuid } from 'uuid';
import Sentry from 'src/services/sentry/Sentry';
import SIGNUP_STEP_ENDED_SCHEMA from 'src/lib/delta/delta-schemas/ppWebCreatorsSignupStepEnded';
import { useEffect } from 'react';
import SIGNUP_FLOW_STARTED_SCHEMA from 'src/lib/delta/delta-schemas/ppWebCreatorsSignupFlowStarted';
import SIGNUP_FLOW_ENDED_SCHEMA from 'src/lib/delta/delta-schemas/ppWebCreatorsSignupFlowEnded';
import { useConnectPlatformsStore } from './store/ConnectPlatformStore';
import { STEP_UUID_MAP } from './stepUuidMap';
import { Platform, PLATFORM_TYPES } from './platforms';

const STORAGE_KEY = 'signup_steps_process_ids';

export const sendSignUpScreenButtonPressedEvent = (buttonName: string) => {
  try {
    const { step } = useConnectPlatformsStore.getState();
    const flow = DeltaHelper.getOrCreateFlow(flowNames.brandsSafetyConnectPlatforms);
    Delta.sendEvent(SIGNUP_BUTTON_PRESSED_SCHEMA.name, {
      flow_id: flow.flow_id,
      screen_presentation_id: STEP_UUID_MAP[step],
      signup_screen_name: step,
      button_name: `${step}-${buttonName}`
    });
  } catch (error: any) {
    Sentry.captureException(error, { title: 'Failed to send signup button pressed event' });
  }
};

function signupStepInputValue(platform: string) {
  return `clicked connect to: ${platform}`;
}

const getStoredProcessIds = (userId: string): Record<Platform, string> => {
  const stored = localStorage.getItem(STORAGE_KEY);
  const allUserIds = stored ? JSON.parse(stored) : {};

  const defaultProcessIds = PLATFORM_TYPES.reduce(
    (acc, platform) => ({
      ...acc,
      [platform]: ''
    }),
    {} as Record<Platform, string>
  );

  return allUserIds[userId] ? { ...defaultProcessIds, ...allUserIds[userId] } : defaultProcessIds;
};

const setStoredProcessId = (userId: string, platform: Platform, processId: string) => {
  const stored = localStorage.getItem(STORAGE_KEY);
  const allUserIds = stored ? JSON.parse(stored) : {};

  allUserIds[userId] = {
    ...getStoredProcessIds(userId),
    [platform]: processId
  };

  localStorage.setItem(STORAGE_KEY, JSON.stringify(allUserIds));
};

const clearStoredProcessId = (userId: string, platform: Platform) => {
  const stored = localStorage.getItem(STORAGE_KEY);
  const allUserIds = stored ? JSON.parse(stored) : {};

  if (allUserIds[userId]) {
    allUserIds[userId][platform] = '';

    if (Object.values(allUserIds[userId]).every((value) => value === '')) {
      delete allUserIds[userId];
    }

    localStorage.setItem(STORAGE_KEY, JSON.stringify(allUserIds));
  }
};

const baseSignupStepStartedPayload = (flow_id: string, processId: string, platform: Platform) => {
  return {
    flow_id,
    process_id: processId,
    signup_provider: platform,
    request_type: 'connect platform',
    input_value: signupStepInputValue(platform),
    number_of_required_fields: 0,
    number_of_fields_completed: 0
  };
};

export const sendSignUpStepStartedEvent = (platform: Platform) => {
  try {
    const flow = DeltaHelper.getOrCreateFlow(flowNames.brandsSafetyConnectPlatforms);
    const processId = uuid();
    const { user } = useConnectPlatformsStore.getState();
    if (user) {
      setStoredProcessId(user.id, platform, processId);
    }
    const signupStepStartedPayload = baseSignupStepStartedPayload(
      flow.flow_id,
      processId,
      platform
    );
    Delta.sendEvent(SIGNUP_STEP_STARTED_SCHEMA.name, signupStepStartedPayload);
  } catch (error: any) {
    Sentry.captureException(error, { title: 'Failed to send signup step started event' });
  }
};

export const sendSignUpStepEndedEvent = (platform: Platform, reason: string, error?: string) => {
  const { user } = useConnectPlatformsStore.getState();
  try {
    const flow = DeltaHelper.getOrCreateFlow(flowNames.brandsSafetyConnectPlatforms);
    let processId = 'Cannot find previous process id';
    if (user) {
      const allProcessIds = getStoredProcessIds(user.id);
      if (allProcessIds[platform]) {
        processId = allProcessIds[platform];
      }
    }
    const signupStepEndedPayload = baseSignupStepStartedPayload(flow.flow_id, processId, platform);
    Delta.sendEvent(SIGNUP_STEP_ENDED_SCHEMA.name, {
      ...signupStepEndedPayload,
      reason,
      error
    });
  } catch (e: any) {
    Sentry.captureException(e, { title: 'Failed to send signup step ended event' });
  } finally {
    if (user) {
      clearStoredProcessId(user.id, platform);
    }
  }
};

export const useSendNewConnectedPlatformEvent = (updatedConnectedPlatforms: Platform[]) => {
  const { user } = useConnectPlatformsStore.getState();
  const flow = DeltaHelper.getOrCreateFlow(flowNames.brandsSafetyConnectPlatforms);
  useEffect(() => {
    if (!user) return;
    const storedProcessIds = getStoredProcessIds(user.id);

    const finishedPlatforms = updatedConnectedPlatforms.filter(
      (platform) => storedProcessIds[platform]
    );
    finishedPlatforms.forEach((platform) => {
      const processId = storedProcessIds[platform];
      if (processId) {
        Delta.sendEvent(SIGNUP_STEP_ENDED_SCHEMA.name, {
          flow_id: flow.flow_id,
          process_id: processId,
          signup_provider: platform,
          request_type: 'connect platform',
          input_value: signupStepInputValue(platform),
          reason: 'success'
        });
        clearStoredProcessId(user.id, platform);
      }
    });
  }, [flow.flow_id, updatedConnectedPlatforms, user]);
};

export const sendSignUpFlowStartedEvent = (scInviteCode?: string) => {
  const flow = DeltaHelper.getOrCreateFlow(flowNames.brandsSafetyConnectPlatforms);
  Delta.sendEvent(SIGNUP_FLOW_STARTED_SCHEMA.name, {
    flow_id: flow.flow_id,
    flow_name: flow.flow_name,
    source: `inviteCode: ${scInviteCode ?? 'in-platform'}`
  });
};

export const sendSignUpFlowEndedEvent = (reason: string) => {
  const flow = DeltaHelper.getOrCreateFlow(flowNames.brandsSafetyConnectPlatforms);
  const { connectedPlatforms } = useConnectPlatformsStore.getState();
  Delta.sendEvent(SIGNUP_FLOW_ENDED_SCHEMA.name, {
    flow_id: flow.flow_id,
    flow_name: flow.flow_name,
    reason,
    ad_networks_connected: JSON.stringify(connectedPlatforms)
  });
};
