import React from 'react';
import { Button, Icon, Headline } from '@lightricks/react-design-system';
import translate from '../../../../utils/translate';
import styles from './SelectPlatformsContent.module.scss';
import { Platform, SOCIAL_PLATFORMS, SocialPlatform } from '../platforms';

interface SelectPlatformsContentProps {
  handleBackClick?: () => void;
  handleNextClick: () => void;
  selectedPlatforms: SocialPlatform[];
  connectedPlatforms: Platform[];
  togglePlatformSelection: (platform: SocialPlatform) => void;
}

// ESLint is stupid, it doesn't understand that switch statements are exhaustive, if it won't be,
// TS will error on the switch statement.
// eslint-disable-next-line consistent-return
const socialPlatformToIcon = (platform: SocialPlatform) => {
  switch (platform) {
    case 'Instagram':
      return 'Instagram';
    case 'TikTok':
      return 'TikTok';
    case 'YouTube':
      return 'YouTube';
    case 'Facebook':
      return 'Facebook';
  }
};

function SelectPlatformsContent({
  handleBackClick,
  handleNextClick,
  selectedPlatforms,
  connectedPlatforms,
  togglePlatformSelection
}: SelectPlatformsContentProps) {
  const renderPlatformButton = (platform: SocialPlatform) => {
    const isSelected = selectedPlatforms.includes(platform);
    const isConnected = connectedPlatforms.includes(platform);
    return (
      <div
        key={platform}
        className={`${styles.platform} ${isSelected ? styles.selectedPlatform : ''} ${
          isConnected ? styles.connectedPlatform : ''
        }`}
        onClick={() => {
          if (!isConnected) {
            togglePlatformSelection(platform);
          }
        }}
        role="button"
        tabIndex={0}>
        <label htmlFor={platform} className={styles.label}>
          <Icon
            name={`Social-${socialPlatformToIcon(platform)}` as any}
            size="large"
            appearance={isSelected ? 'white' : 'primary'}
          />
          <p>{platform}</p>
        </label>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {handleBackClick && (
          <div className={styles.headerRow}>
            <span
              className={styles.backButton}
              onClick={handleBackClick}
              data-testid="close-button"
              role="button"
              tabIndex={0}>
              <Icon name="Actions-Close-Normal" size="medium" appearance="primary" />
            </span>
          </div>
        )}
        <div className={styles.mainContent}>
          <div className={styles.headlineRow}>
            <Headline size="md" className={styles.headline}>
              {translate('components.brand-safety.sign-up.select-platforms.headline')}
            </Headline>
          </div>
          <div className={styles.platforms}>{SOCIAL_PLATFORMS.map(renderPlatformButton)}</div>
          <Button
            onClick={handleNextClick}
            disabled={selectedPlatforms.length === 0}
            appearance="neutral"
            mode="filled"
            size="large"
            className={styles.nextButton}>
            {translate('components.brand-safety.sign-up.select-platforms.next')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default SelectPlatformsContent;
