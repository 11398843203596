import React, { useEffect, useState } from 'react';
import { Button, Headline, Body, Input } from '@lightricks/react-design-system';
import { v4 as uuid } from 'uuid';
import styles from './SignUp.module.scss';
import useLtxToken from '../../../hooks/use-ltx-token';
import OtpVerificationModal from '../../../components/otp-verification-modal';
import useAuthenticate from '../../../hooks/use-authenticate';
import useToken from '../../../hooks/use-token';
import useParams from '../../../hooks/use-params/useParams';
import isMobile from '../../../utils/identifyDevice';
import translate from '../../../utils/translate';
import authRequests from '../../../api/auth';
import emailValidator from '../../../utils/validators/email';
import useNavigation from '../../../hooks/use-navigation';
import usePageTitle from '../../../hooks/use-page-title';
import Link from '../../../components/link';
import Checkbox from '../../../components/checkbox';
import DeltaHelper from '../../../services/delta/DeltaHelper';
import {
  buttonNames,
  deltaClientEvents,
  flowNames,
  reasonNames,
  resultNames,
  screenNames
} from '../../../lib/delta/deltaConstants';
import Delta from '../../../utils/wrappers/Delta';

function SignUp() {
  usePageTitle(translate('meta-tags.register.title'));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const params = useParams();
  const isMobileDevice = isMobile();
  const { onSubmit } = useAuthenticate();

  const [form, setForm] = useState({
    email: '',
    isTermsChecked: false
  });
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [isTermsCheckedErrorMessage, setIsTermsCheckedErrorMessage] = useState('');
  const [formValidation, setFormValidation] = useState({
    email: false,
    isTermsChecked: false
  });

  const { setToken } = useToken();
  const navigation = useNavigation();
  const { setLtxToken } = useLtxToken();

  useEffect(() => {
    const loginFlowEvent = DeltaHelper.createFlow(flowNames.auth.signup);
    DeltaHelper.startFlow(flowNames.auth.signup);
    DeltaHelper.screenPresented(screenNames.auth.signup, '', {
      trigger_flow_id: loginFlowEvent.flow_id,
      trigger_flow_name: loginFlowEvent.flow_name
    });
  }, []);

  const onError = (error: string, stepDateEvent: Record<string, unknown>) => {
    setEmailErrorMessage(error);

    Delta.sendEvent(deltaClientEvents.auth.signup_step_ended, {
      ...stepDateEvent,
      reason: reasonNames.failure,
      error
    });
  };

  const onValidateForm = () => {
    let isErrorOnSubmit = false;
    let numberOfValidatedFields = 2;
    if (isSubmitting) {
      isErrorOnSubmit = true;
    }
    let emailError = '';
    if (!formValidation.email) {
      numberOfValidatedFields -= 1;
      isErrorOnSubmit = true;
      if (form.email === '') {
        emailError = translate('components.register-form.errors.empty');
        setEmailErrorMessage(emailError);
      } else {
        emailError = translate('components.register-form.errors.format');
      }
    }
    let isTermsCheckedError = '';
    if (!formValidation.isTermsChecked) {
      numberOfValidatedFields -= 1;
      isErrorOnSubmit = true;
      isTermsCheckedError = translate('components.login-form.terms-and-privacy-unchecked');
      setIsTermsCheckedErrorMessage(isTermsCheckedError);
    }
    return {
      isErrorOnSubmit,
      validationError: [emailError, isTermsCheckedError].join(', '),
      numberOfValidatedFields
    };
  };

  const handleSubmit = async () => {
    const signupFlowEvent = DeltaHelper.getOrCreateFlow(flowNames.auth.signup);
    Delta.sendEvent(deltaClientEvents.generic.button_pressed, {
      button_name: buttonNames.auth.join_now,
      screen_name: screenNames.auth.signup,
      flow_id: signupFlowEvent.flow_id,
      flow_name: signupFlowEvent.flow_name,
      triggered_flow_id: '',
      triggered_flow_name: '',
      screen_presentation_id: DeltaHelper.getActiveScreenPresented().screen_presentation_id
    });
    const { isErrorOnSubmit, validationError, numberOfValidatedFields } = onValidateForm();
    const processId = uuid();
    const stepDateEvent = {
      flow_id: signupFlowEvent.flow_id,
      process_id: processId,
      signup_provider: 'fortress',
      request_type: 'send_otp',
      input_value: form.email,
      number_of_required_fields: 2,
      number_of_fields_completed: numberOfValidatedFields
    };
    Delta.sendEvent(deltaClientEvents.auth.signup_step_started, stepDateEvent);

    if (isErrorOnSubmit) {
      Delta.sendEvent(deltaClientEvents.auth.signup_step_ended, {
        ...stepDateEvent,
        reason: reasonNames.failure,
        error: validationError
      });
      return;
    }
    setIsSubmitting(true);
    const isSubmitSuccess = await onSubmit('register', form.email, (error: string) =>
      onError(error, stepDateEvent)
    );
    setIsSubmitting(false);
    if (isSubmitSuccess) {
      setIsModalOpen(true);
      setEmailErrorMessage('');
      setIsTermsCheckedErrorMessage('');

      Delta.sendEvent(deltaClientEvents.auth.signup_step_ended, {
        ...stepDateEvent,
        reason: reasonNames.success
      });
      DeltaHelper.screenDismissed(screenNames.auth.signup, screenNames.auth.signup_otp);
      DeltaHelper.screenPresented(screenNames.auth.signup_otp, screenNames.auth.signup);
    }
  };

  const onCreateUser = async (token: string, stepDateEvent: Record<string, unknown>) => {
    try {
      const registerFormData = {
        ...params,
        ...form,
        password: token
      };

      const { response, sessionData } = await authRequests.register(registerFormData);

      const tokenSaved = await setToken(sessionData);
      if (!tokenSaved) {
        Delta.sendEvent(deltaClientEvents.auth.signup_step_ended, {
          ...stepDateEvent,
          reason: reasonNames.failure,
          error: 'Token not saved'
        });
        return;
      }
      setLtxToken(token);

      navigation.navigate({ screen: '', url: '/' });

      Delta.sendEvent(deltaClientEvents.auth.signup_step_ended, {
        ...stepDateEvent,
        creator_id: response?.data?.data?.id || '',
        reason: reasonNames.success
      });
      DeltaHelper.screenDismissed(screenNames.auth.signup, '');
      DeltaHelper.endFlow(flowNames.auth.signup, {
        result: resultNames.success
      });
    } catch (error: any) {
      const errorDescription = error?.response?.data?.errors?.[0]?.title;
      let message = translate('components.register-form.errors.default');
      if (errorDescription === 'Email has already been taken') {
        message = translate('components.register-form.errors.email-exists');
      } else if (errorDescription === 'Email is not an email') {
        message = translate('components.register-form.errors.not-email');
      }

      setEmailErrorMessage(message);
      setIsModalOpen(false);

      Delta.sendEvent(deltaClientEvents.auth.signup_step_ended, {
        ...stepDateEvent,
        reason: reasonNames.failure,
        error: message
      });
    }
  };

  const setFormValue = (key: string, value: string | boolean) => {
    setForm({ ...form, [key]: value });
  };

  const setEmailValue = (value: string) => {
    setFormValue('email', value);
    setEmailErrorMessage('');
  };

  const setTermAndPrivacy = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
    const value = event.target.checked;
    setFormValue(key, value);
    setFormValidation({ ...formValidation, [key]: value });
    setIsTermsCheckedErrorMessage('');
  };

  const setEmailValidation = (isValid: boolean): void => {
    setFormValidation({ ...formValidation, email: isValid });
  };

  const renderTermsAndPrivacy = () => {
    return (
      <Body
        size={isMobileDevice ? 'sm' : 'lg'}
        dangerouslyInnerHTML={translate('components.register-form.terms-label')}
      />
    );
  };

  const onNavigateToLogin = (e: Event) => {
    e.stopPropagation();
    DeltaHelper.screenDismissed(screenNames.auth.signup, screenNames.auth.login);
    DeltaHelper.endFlow(flowNames.auth.signup, {
      result: resultNames.cancelled
    });
  };
  const emailValidators = [
    {
      validator: (email: string | undefined) => email !== '',
      errorMessage: translate('components.register-form.errors.empty')
    },
    {
      validator: emailValidator,
      errorMessage: translate('components.register-form.errors.format')
    }
  ];

  return (
    <div data-testid="signup" className={styles.signupFormWrapper}>
      <OtpVerificationModal
        isOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        email={form.email}
        authenticateCallback={onCreateUser}
        authFlowType={flowNames.auth.signup}
      />
      <div className={styles.text}>
        <Headline size={isMobileDevice ? 'md' : 'xl'} className={styles.signupTitle}>
          {translate('templates.auth.register.title')}
        </Headline>
        <Body size={isMobileDevice ? 'md' : 'xl'}>
          {translate('templates.auth.register.welcome-message.first-line')}
        </Body>
        <Body size={isMobileDevice ? 'md' : 'xl'}>
          {translate('templates.auth.register.welcome-message.second-line')}
        </Body>
      </div>
      <form
        onSubmit={(event: React.FormEvent): void => {
          event.preventDefault();
          handleSubmit();
        }}
        className={styles.formContent}>
        <div className={styles.formInput}>
          <Input
            testID="signup--email-input"
            validatorOptions={{
              type: 'email',
              onValidation: setEmailValidation,
              validators: emailValidators,
              overrideError: emailErrorMessage,
              onChangeText: setEmailValue
            }}
            label={translate('components.register-form.email-label')}
            placeholder={translate('components.register-form.email-placeholder')}
            value={form.email}
          />
        </div>
        <div className={styles.signupCheckbox}>
          <Checkbox
            label={renderTermsAndPrivacy()}
            checked={form.isTermsChecked}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setTermAndPrivacy(event, 'isTermsChecked');
            }}
            error={isTermsCheckedErrorMessage}
          />
        </div>
        <Button
          testID="signup--submit-button"
          type="submit"
          disabled={isSubmitting}
          appearance="brand"
          mode="filled"
          size="large"
          onClick={handleSubmit}
          isLoading={isSubmitting}
          fullWidth>
          {translate('components.register-form.submit-text')}
        </Button>
      </form>
      <div className={styles.formNoteLogin}>
        <Body size={isMobileDevice ? 'md' : 'lg'} className={styles.text}>
          {translate('templates.auth.register.note--login.text')}
          <Link
            className={styles.formLink}
            to={translate('templates.auth.register.note--login.link-term')}
            onClick={onNavigateToLogin}>
            {translate('templates.auth.register.note--login.text-link')}
          </Link>
        </Body>
      </div>
    </div>
  );
}

export default SignUp;
