export const STEPS = {
  SELECT_PLATFORMS: 'select-platforms',
  SYNC_PLATFORMS: 'sync-platforms',
  INSTAGRAM_INSTRUCTIONS: 'instagram-instructions',
  FINISHED: 'finished-flow',
  UNFINISHED_FLOW: 'unfinished-flow',
  SUBMIT_BIRTHDATE: 'submit-birthdate',
  MAYBE_LATER: 'maybe-later'
} as const;

export type Step = (typeof STEPS)[keyof typeof STEPS];
