import { useEffect, useState } from 'react';
import useUserQuery from 'src/hooks/queries/use-user-query';
import { queryClient } from 'src/utils/reactQueryClient';
import { useMutation } from '@tanstack/react-query';
import userUpdaters from '../../../api/updaters/user';
import { useConnectPlatformsStore, User } from './store/ConnectPlatformStore';
import { SocialPlatform } from './platforms';

export const providerToPlatformMap = {
  instagram: 'Instagram',
  tiktok_v2: 'TikTok',
  youtube: 'YouTube',
  facebook: 'Facebook'
} as const;

export function useUpdateUserData() {
  const { setUser, setConnectedPlatforms, setAvatarUrl, avatarUrl, setDistinctConnectedPlatforms } =
    useConnectPlatformsStore();

  const [birthDateUpdateError, setBirthDateUpdateError] = useState<string | null>(null);

  const updateBirthdayMutation = useMutation({
    mutationFn: (date: Date) => {
      return userUpdaters.updateBirthdate(date.toISOString());
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['user'] });
    },
    onError: () => {
      setBirthDateUpdateError('Failed to update birthday. Please try again.');
    }
  });

  const { user, isLoading, isError } = useUserQuery<User>({});

  useEffect(() => {
    if (!isLoading && !isError && user) {
      setUser(user);
      if (user?.identities) {
        const platforms = user.identities
          .map((identity) => {
            const { provider } = identity;
            if (isValidProvider(provider)) {
              return providerToPlatformMap[provider];
            }
            return null;
          })
          .filter((platform): platform is NonNullable<SocialPlatform> => platform !== null);
        setConnectedPlatforms(platforms);

        const identityWithAvatar = user.identities.find(
          ({ cachedProfileImageUrl }) => cachedProfileImageUrl
        );
        if (identityWithAvatar?.cachedProfileImageUrl && !avatarUrl) {
          setAvatarUrl(identityWithAvatar.cachedProfileImageUrl);
        }

        const distinctConnectedPlatforms = platforms.filter(
          (platform): platform is NonNullable<SocialPlatform> =>
            platform !== 'Facebook' && platforms.includes(platform)
        );
        setDistinctConnectedPlatforms(distinctConnectedPlatforms);
      }
    }
  }, [
    isError,
    isLoading,
    setConnectedPlatforms,
    setUser,
    user,
    setAvatarUrl,
    avatarUrl,
    setDistinctConnectedPlatforms
  ]);

  return {
    isLoading,
    isError,
    updateBirthday: updateBirthdayMutation.mutate,
    isLoadingBirthday: updateBirthdayMutation.isLoading,
    birthdayError: birthDateUpdateError,
    userBirthDate: user?.birthDate
  };
}

function isValidProvider(provider: string): provider is keyof typeof providerToPlatformMap {
  return provider in providerToPlatformMap;
}
