import { useMutation } from '@tanstack/react-query';
import vettingOptOutUpdaters from '../../../../api/updaters/vettingOptOut';

function useVettingOptOut() {
  return useMutation({
    mutationKey: ['vettingOptOut'],
    mutationFn: vettingOptOutUpdaters.optOut
  });
}

export default useVettingOptOut;
