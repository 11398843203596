import { useQuery } from '@tanstack/react-query';
import userFetchers from '../../../api/fetchers/user';
import useToken from '../../use-token';

function useUserQuery<T = any>({
  enabled = true,
  overrideEnabled = false,
  include
}: {
  enabled?: boolean;
  overrideEnabled?: boolean;
  include?: string[];
}) {
  const { isAuthenticated } = useToken(true, true);
  const queryKey = include ? ['user', ...include] : ['user'];

  if (overrideEnabled) {
    queryKey.push('overrideEnabled');
  }

  const results = useQuery<{ data: T }>({
    queryKey,
    queryFn: userFetchers.user,
    enabled: (isAuthenticated && enabled) || overrideEnabled,
    meta: {
      include
    }
  });
  return {
    ...results,
    user: results.data?.data
  };
}

export default useUserQuery;
