import React from 'react';
import { Button, Label, Icon } from '@lightricks/react-design-system';
import unfinishedFlowAnimation from '../../../../assets/lotties/brand-safety/unfinished_flow_animation.json';
import translate from '../../../../utils/translate';
import styles from './UnfinishedFlow.module.scss';
import UnfinishedFlowBase from './UnfinishedFlowBase';
import { Platform } from '../platforms';
import { sendSignUpScreenButtonPressedEvent } from '../useAnalytics';

export type UnfinishedFlowProps = {
  handleFinishConnecting: () => void;
  missingPlatform?: Platform;
  helpLink?: string;
  handleBack: () => void;
  brandName: string;
  handleMaybeLater: () => void;
};

const defaultHelpLink =
  'https://popularpays.zendesk.com/hc/en-us/sections/360012325391-Account-Set-Up';

function UnfinishedFlow({
  handleFinishConnecting,
  missingPlatform,
  helpLink = defaultHelpLink,
  handleBack,
  brandName,
  handleMaybeLater
}: UnfinishedFlowProps) {
  return (
    <UnfinishedFlowBase
      animation={unfinishedFlowAnimation}
      headline={translate('components.brand-safety.sign-up.unfinished-flow.dont-miss-out', {
        brandName
      })}
      description={translate('components.brand-safety.sign-up.unfinished-flow.connect-socials')}>
      <Button
        onClick={() => {
          if (missingPlatform) {
            sendSignUpScreenButtonPressedEvent(`connect-${missingPlatform}`);
            handleFinishConnecting();
          } else {
            sendSignUpScreenButtonPressedEvent('finish-connecting');
            handleBack();
          }
        }}
        appearance="neutral"
        size="large"
        mode="filled">
        {missingPlatform && (
          <Icon name={`Social-${missingPlatform}` as any} size="medium" appearance="white" />
        )}
        {missingPlatform
          ? translate('components.brand-safety.sign-up.unfinished-flow.connect-platform', {
              platform: missingPlatform
            })
          : translate('components.brand-safety.sign-up.unfinished-flow.finish-connecting')}
      </Button>
      <div>
        <span
          onClick={() => {
            sendSignUpScreenButtonPressedEvent('maybe-later');
            handleMaybeLater();
          }}
          role="button"
          tabIndex={0}>
          <Label size="lg" className={styles.maybeLater}>
            {translate('components.brand-safety.sign-up.unfinished-flow.maybe-later')}
          </Label>
        </span>
        <Label size="lg" className={styles.helpText}>
          <a
            href={helpLink}
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              sendSignUpScreenButtonPressedEvent('help');
            }}>
            {translate('components.brand-safety.sign-up.unfinished-flow.need-help')}
          </a>
          {translate('components.brand-safety.sign-up.unfinished-flow.guidance')}
        </Label>
      </div>
    </UnfinishedFlowBase>
  );
}

export default UnfinishedFlow;
