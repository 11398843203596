import React from 'react';
import { Headline, Label } from '@lightricks/react-design-system';
import { Player } from '@lottiefiles/react-lottie-player';
import styles from './UnfinishedFlow.module.scss';

export type UnfinishedFlowBaseProps = {
  animation: any;
  headline: string;
  description: string;
  children: React.ReactNode;
};

function UnfinishedFlowBase({
  animation,
  headline,
  description,
  children
}: UnfinishedFlowBaseProps) {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Player autoplay loop src={animation} className={styles.animation} />
        <div className={styles.text}>
          <Headline size="lg" className={styles.headline}>
            {headline}
          </Headline>
          <Label size="lg">{description}</Label>
        </div>
        <div className={styles.buttons}>{children}</div>
      </div>
    </div>
  );
}

export default UnfinishedFlowBase;
