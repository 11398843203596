import { SocialPlatform } from './platforms';
import { useConnectPlatformsStore } from './store/ConnectPlatformStore';

// eslint-disable-next-line import/prefer-default-export
export const useSelectPlatforms = () => {
  const { selectedPlatforms, setSelectedPlatforms } = useConnectPlatformsStore();

  const togglePlatformSelection = (platform: SocialPlatform) => {
    if (selectedPlatforms.includes(platform)) {
      setSelectedPlatforms(selectedPlatforms.filter((p) => p !== platform));
    } else {
      setSelectedPlatforms([...selectedPlatforms, platform]);
    }
  };

  return { togglePlatformSelection };
};
