import i18n from 'i18next';
import translationEn from '../locales/en.json';

type NestedKeyOf<Obj extends object> = {
  [Key in keyof Obj]: Obj[Key] extends object
    ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      `${Key}` | `${Key}.${NestedKeyOf<Obj[Key]>}`
    : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      `${Key}`;
}[keyof Obj];

type TranslationKeys = NestedKeyOf<typeof translationEn>;

/**
 * translation helper function
 * @param value
 * @param options
 */
const translate = (value: TranslationKeys | string, options?: object) => {
  if (i18n.exists(value)) {
    return `${i18n.t(value, options)}`;
  }
  return `${value}`;
};

export default translate;
