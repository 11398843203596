import React, { useRef } from 'react';
import { Label } from '@lightricks/react-design-system';
import sandClockPendingAnimation from '../../../../assets/lotties/brand-safety/sand_clock_pending_animation.json';
import translate from '../../../../utils/translate';
import styles from './UnfinishedFlow.module.scss';
import UnfinishedFlowBase from './UnfinishedFlowBase';

type MaybeLaterProps = {
  handleBack: () => void;
};

function MaybeLater({ handleBack }: MaybeLaterProps) {
  const emailSent = localStorage.getItem('finish-sync-email-sent');

  return (
    <UnfinishedFlowBase
      animation={sandClockPendingAnimation}
      headline={translate('components.brand-safety.sign-up.unfinished-flow.almost-there')}
      description={
        emailSent
          ? translate('components.brand-safety.sign-up.unfinished-flow.returning-description')
          : translate('components.brand-safety.sign-up.unfinished-flow.first-time-description')
      }>
      <span onClick={handleBack} role="button" tabIndex={0} className={styles.finishFlowButton}>
        <Label size="lg">
          {translate('components.brand-safety.sign-up.unfinished-flow.take-me-back')}
        </Label>
      </span>
    </UnfinishedFlowBase>
  );
}

export default MaybeLater;
