export const PLATFORM_TYPES = ['Instagram', 'TikTok', 'YouTube', 'Facebook', 'Email'] as const;
export const VETTABLE_PLATFORMS: readonly Platform[] = ['Instagram', 'TikTok'] as const;
export const SOCIAL_PLATFORMS = ['Instagram', 'TikTok', 'YouTube', 'Facebook'] as const;
export type Platform = (typeof PLATFORM_TYPES)[number];
export type SocialPlatform = (typeof SOCIAL_PLATFORMS)[number];

export const isVettablePlatform = (platform: Platform): boolean =>
  VETTABLE_PLATFORMS.includes(platform);

export function calculateVettablePlatformsCompletion(
  connectedPlatforms: Platform[],
  selectedPlatforms: Platform[]
): number {
  if (selectedPlatforms.length === 0) {
    return 0;
  }
  if (!selectedPlatforms.some((platform) => isVettablePlatform(platform))) {
    return 100;
  }

  const vettableConnected = connectedPlatforms.filter((platform) => isVettablePlatform(platform));
  const vettableSelected = selectedPlatforms.filter((platform) => isVettablePlatform(platform));

  return vettableSelected.length ? (vettableConnected.length / vettableSelected.length) * 100 : 0;
}
