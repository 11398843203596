import { v4 as uuid } from 'uuid';
import { STEPS, Step } from './wizardSteps';

// eslint-disable-next-line import/prefer-default-export
export const STEP_UUID_MAP: Record<Step, string> = Object.keys(STEPS).reduce((map, key) => {
  const step = STEPS[key as keyof typeof STEPS];
  return {
    ...map,
    [step]: uuid()
  };
}, {} as Record<Step, string>);
