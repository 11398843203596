import React from 'react';
import {
  Icon,
  Headline,
  Label,
  Avatar,
  designSystemToken,
  Button,
  Body
} from '@lightricks/react-design-system';
import translate from 'src/utils/translate';
import { IconName } from '@lightricks/react-design-system/dist/components/icon/iconNames';
import { AnimatePresence, motion } from 'framer-motion';
import styles from './SyncPlatforms.module.scss';
import ProgressBar from '../../ProgressBar';
import { Platform } from '../platforms';
import { INFO_MESSAGES, INFO_BOX_STYLES } from '../../constants/platformConstants';
import PlatformList from './PlatformList';

type SyncPlatformsContentProps = {
  handleBack?: () => void;
  handleSkip: () => void;
  avatarUrl?: string;
  error?: string;
  onErrorDismiss: () => void;
  completionPercentage: number;
  PlatformListProps: {
    selectedPlatforms: Platform[];
    connectedPlatforms: Platform[];
    onConnectPlatform: (platform: Platform) => void;
  };
};

function ErrorBox({
  error,
  handleCloseErrorBox
}: {
  error: string;
  handleCloseErrorBox: () => void;
}) {
  return (
    <motion.div
      className={styles.errorBox}
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: 'auto' }}
      exit={{ opacity: 0, height: 0 }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}>
      <div
        className={styles.errorBoxCloseButton}
        onClick={handleCloseErrorBox}
        role="button"
        tabIndex={0}>
        <Icon name="Actions-Close-Small" size="large" appearance="secondary" />
      </div>
      <Icon name="Actions-Patch-Cancel-Line" size="medium" appearance="danger" />
      <Body size="md" className={styles.errorBoxMessage}>
        {error}
      </Body>
    </motion.div>
  );
}

function InfoBox({
  infoIcon,
  infoMessage,
  connectedPlatforms
}: {
  infoIcon: IconName;
  infoMessage: string;
  connectedPlatforms: Platform[];
}) {
  const infoBoxStyle =
    INFO_BOX_STYLES[connectedPlatforms.length as keyof typeof INFO_BOX_STYLES] ||
    INFO_BOX_STYLES.default;

  return (
    <motion.div
      className={`${styles.infoBox} ${infoBoxStyle}`}
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: 'auto' }}
      exit={{ opacity: 0, height: 0 }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}>
      <Icon name={infoIcon} size="large" appearance="primary" />
      <p>{infoMessage}</p>
    </motion.div>
  );
}

function SyncPlatformsContent({
  handleBack,
  handleSkip,
  avatarUrl,
  error,
  onErrorDismiss,
  PlatformListProps,
  completionPercentage
}: SyncPlatformsContentProps) {
  return (
    <div className={styles.container} data-testid="sync-platforms">
      <div className={styles.content}>
        <div className={styles.headerRow}>
          <Button
            onClick={handleBack}
            style={{ visibility: handleBack ? 'visible' : 'hidden' }}
            data-testid="back-button"
            appearance="neutral"
            mode="plain"
            size="large"
            className={styles.backButton}>
            <Icon name="Actions-Arrow-Back-Small" size="large" appearance="primary" />
          </Button>
          <span onClick={handleSkip} role="button" tabIndex={0} className={styles.skipButton}>
            <Label size="lg">{translate('components.brand-safety.skip')}</Label>
          </span>
        </div>
        <div className={styles.mainContent}>
          <div className={styles.progressBarWrapper}>
            <ProgressBar value={completionPercentage} />
            {translate('components.brand-safety.sign-up.sync-platforms.progress', {
              progress: completionPercentage.toFixed(0)
            })}
          </div>
          <div className={styles.titleSection}>
            {avatarUrl ? (
              <Avatar
                size="3xl"
                src={avatarUrl}
                type="picture"
                variant="circular"
                background={designSystemToken('semantic.bg.tertiary')}
              />
            ) : (
              <Headline size="xl" className={styles.avatarPlaceholder}>
                {translate('components.brand-safety.sign-up.sync-platforms.heading')}
              </Headline>
            )}
            <AnimatePresence mode="wait">
              {error ? (
                <ErrorBox key="error-box" error={error} handleCloseErrorBox={onErrorDismiss} />
              ) : (
                <InfoBox
                  key="info-box"
                  infoIcon={INFO_MESSAGES[0].icon}
                  infoMessage={translate(INFO_MESSAGES[0].message)}
                  connectedPlatforms={PlatformListProps.connectedPlatforms}
                />
              )}
            </AnimatePresence>
          </div>
          <PlatformList {...PlatformListProps} />
        </div>
      </div>
    </div>
  );
}

export default SyncPlatformsContent;
