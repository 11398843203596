import React, { useEffect, useMemo } from 'react';
import authenticateFacebook from 'src/services/facebook/Facebook';
import authenticateTiktok from 'src/services/tiktok_creator_market_place/TiktokCreatorMarketPlace';
import authenticateYouTube from 'src/services/youtube/Youtube';
import Delta from 'src/utils/wrappers/Delta';
import userNotifications from 'src/api/updaters/userNotifications';
import Sentry from 'src/services/sentry/Sentry';
import { useConnectPlatformsStore } from './store/ConnectPlatformStore';
import SelectPlatformsContent from './steps/SelectPlatformsContent';
import {
  calculateVettablePlatformsCompletion,
  isVettablePlatform,
  Platform,
  SOCIAL_PLATFORMS
} from './platforms';
import { useUpdateUserData } from './useUserData';
import { useSelectPlatforms } from './useSelectPlatforms';
import useInitizlizeSearchParams from './useInitizlizeSearchParams';
import SyncPlatformsContent from './steps/SyncPlatformsContent';
import InstagramInstructions from './steps/InstagramInstructions';
import FinishedFlow from './steps/FinishedFlow';
import UnfinishedFlow from './steps/UnfinishedFlow';
import MaybeLater from './steps/MaybeLater';
import {
  sendSignUpFlowEndedEvent,
  sendSignUpScreenButtonPressedEvent,
  sendSignUpStepEndedEvent,
  sendSignUpStepStartedEvent,
  useSendNewConnectedPlatformEvent
} from './useAnalytics';
import { STEPS } from './wizardSteps';
import BirthdayInput from './steps/BirthdayInput';

const SENT_FINISHED_FLOW_EMAIL_KEY = 'sent-finished-flow-email';
const FINISH_SYNC_EMAIL_SENT_KEY = 'finish-sync-email-sent';

export default function ConnectPlatformsWizard() {
  const {
    step,
    previousStep,
    setStep,
    selectedPlatforms,
    connectedPlatforms,
    setError,
    errorMessage,
    avatarUrl,
    distinctConnectedPlatforms,
    user,
    brandName
  } = useConnectPlatformsStore();
  const { userBirthDate, birthdayError, updateBirthday, isLoadingBirthday } = useUpdateUserData();

  const updateSearchParams = useInitizlizeSearchParams([...SOCIAL_PLATFORMS]);
  useSendNewConnectedPlatformEvent(distinctConnectedPlatforms);
  useEffect(() => {
    if (!userBirthDate) {
      setStep(STEPS.SUBMIT_BIRTHDATE);
    } else if (step === STEPS.SUBMIT_BIRTHDATE) {
      setStep(STEPS.SELECT_PLATFORMS);
    }
  }, [setStep, userBirthDate, step]);

  const completionPercentage = useMemo(() => {
    return calculateVettablePlatformsCompletion(connectedPlatforms, selectedPlatforms);
  }, [connectedPlatforms, selectedPlatforms]);

  const handleNextClick = (buttonName = 'next') => {
    sendSignUpScreenButtonPressedEvent(buttonName);
    switch (step) {
      case STEPS.SELECT_PLATFORMS:
        if (selectedPlatforms.length > 0) {
          updateSearchParams({
            selectedPlatforms: selectedPlatforms.join(',')
          });
          if (completionPercentage === 100) {
            setStep(STEPS.FINISHED);
          } else {
            setStep(STEPS.SYNC_PLATFORMS);
          }
        }
        break;
      case STEPS.SYNC_PLATFORMS:
        setStep(STEPS.UNFINISHED_FLOW);
        break;
      case STEPS.INSTAGRAM_INSTRUCTIONS:
        setStep(STEPS.UNFINISHED_FLOW);
        break;
      case STEPS.UNFINISHED_FLOW:
        setStep(STEPS.MAYBE_LATER);
        break;
      case STEPS.SUBMIT_BIRTHDATE:
      case STEPS.FINISHED:
      case STEPS.MAYBE_LATER:
        break;
    }
  };

  const handleBackClick = (buttonName = 'back') => {
    if (step === STEPS.SYNC_PLATFORMS) {
      setStep(STEPS.SELECT_PLATFORMS);
      return;
    }
    if (step === STEPS.MAYBE_LATER) {
      setStep(STEPS.SYNC_PLATFORMS);
      return;
    }
    sendSignUpScreenButtonPressedEvent(buttonName);
    setStep(previousStep);
  };

  const missingPlatforms = selectedPlatforms.filter(
    (platform) => !connectedPlatforms.includes(platform) && isVettablePlatform(platform)
  );

  const { togglePlatformSelection } = useSelectPlatforms();

  const redirectToHome = async () => {
    sendSignUpScreenButtonPressedEvent('redirect-to-home');
    await Delta.flushEvents();
    window.location.href = '/';
  };

  const sendFinishedFlowEmail = async () => {
    const stored = localStorage.getItem(SENT_FINISHED_FLOW_EMAIL_KEY);
    const allSentEmailsUserIds = stored ? JSON.parse(stored) : {};
    if (!user?.id || !brandName || allSentEmailsUserIds[user.id]) return;

    try {
      await userNotifications.sendFinishedFlowEmail(user.id, brandName);
      allSentEmailsUserIds[user.id] = true;
      localStorage.setItem(SENT_FINISHED_FLOW_EMAIL_KEY, JSON.stringify(allSentEmailsUserIds));
    } catch (error: any) {
      Sentry.captureException(error, { title: 'Failed to send finished flow email' });
    }
  };

  const sendUnfinishedFlowEmail = async () => {
    const stored = localStorage.getItem(FINISH_SYNC_EMAIL_SENT_KEY);
    const allSentEmailsUserIds = stored ? JSON.parse(stored) : {};
    if (!user?.id || !brandName || allSentEmailsUserIds[user.id]) return;
    try {
      await userNotifications.sendUnfinishedFlowEmail(user.id, brandName);
      allSentEmailsUserIds[user.id] = true;
      localStorage.setItem(FINISH_SYNC_EMAIL_SENT_KEY, JSON.stringify(allSentEmailsUserIds));
    } catch (error: any) {
      Sentry.captureException(error, { title: 'Failed to send unfinished flow email' });
    }
  };

  const handleConnectPlatform = async (platform: Platform) => {
    sendSignUpScreenButtonPressedEvent(`connect-${platform}`);
    sendSignUpStepStartedEvent(platform);
    if (platform === 'Instagram') {
      setStep(STEPS.INSTAGRAM_INSTRUCTIONS);
      return;
    }
    try {
      switch (platform) {
        case 'TikTok':
          await authenticateTiktok(user?.id ?? null, selectedPlatforms, brandName ?? '');
          break;
        case 'YouTube':
          await authenticateYouTube(user?.id ?? null, selectedPlatforms, brandName ?? '');
          break;
        case 'Facebook':
          await authenticateFacebook(user?.id ?? null, selectedPlatforms, brandName ?? '');
          break;
        default:
          throw new Error(`Platform ${platform} is not supported.`);
      }
    } catch (err) {
      sendSignUpStepEndedEvent(platform, 'failure', JSON.stringify(err));
      setError(`Failed to connect to ${platform}. Please try again.`);
    }
  };

  switch (step) {
    case STEPS.SELECT_PLATFORMS:
      return (
        <SelectPlatformsContent
          connectedPlatforms={connectedPlatforms}
          handleNextClick={handleNextClick}
          selectedPlatforms={selectedPlatforms}
          togglePlatformSelection={(platform) => {
            togglePlatformSelection(platform);
            sendSignUpScreenButtonPressedEvent(platform);
          }}
        />
      );
    case STEPS.SYNC_PLATFORMS:
      return (
        <SyncPlatformsContent
          handleBack={handleBackClick}
          handleSkip={() => handleNextClick('skip')}
          completionPercentage={completionPercentage}
          PlatformListProps={{
            selectedPlatforms,
            connectedPlatforms: distinctConnectedPlatforms,
            onConnectPlatform: handleConnectPlatform
          }}
          error={errorMessage}
          onErrorDismiss={() => setError(undefined)}
          avatarUrl={avatarUrl}
        />
      );
    case STEPS.INSTAGRAM_INSTRUCTIONS:
      return (
        <InstagramInstructions
          handleBack={handleBackClick}
          handleSkip={handleNextClick}
          userId={user?.id ?? null}
          selectedPlatforms={selectedPlatforms}
        />
      );
    case STEPS.FINISHED: {
      sendFinishedFlowEmail();
      sendSignUpFlowEndedEvent('success');
      return <FinishedFlow brandName={brandName ?? ''} redirectToHome={redirectToHome} />;
    }
    case STEPS.UNFINISHED_FLOW: {
      sendUnfinishedFlowEmail();
      return (
        <UnfinishedFlow
          brandName={brandName ?? ''}
          handleFinishConnecting={() => handleConnectPlatform(missingPlatforms[0])}
          missingPlatform={missingPlatforms[0]}
          handleMaybeLater={handleNextClick}
          handleBack={handleBackClick}
        />
      );
    }
    case STEPS.MAYBE_LATER:
      return <MaybeLater handleBack={handleBackClick} />;
    case STEPS.SUBMIT_BIRTHDATE:
      return (
        <BirthdayInput
          errorMessage={birthdayError}
          onSubmit={updateBirthday}
          isLoading={isLoadingBirthday}
        />
      );
  }
}
